import { NewStudentData } from "@/api/student";
import { Classroom } from "@/entities/classroom";
import { StudentGrade } from "@/entities/student";
import { generateNewStudentId } from "@/utils/student";
import parse from "csv-parse/lib/sync";
import fileSaver from "file-saver";
import encoding from "encoding-japanese";
import { generateRandomPassword, validPassword } from "./password";
import { CustomProperty } from "@/entities/custom_property";

export async function readAsText(
  blob: Blob,
  encoding = "UTF-8"
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsText(blob, encoding);
  });
}

type StudentRow = {
  name: string;
  classroomName: string;
  grade: StudentGrade;
  id: string;
  password: string;
  loginId: string;
  customProperties: { title: string; value: string | number }[];
};

const studentGrades: StudentGrade[] = [
  "小1",
  "小2",
  "小3",
  "小4",
  "小5",
  "小6",
  "中1",
  "中2",
  "中3",
  "高1",
  "高2",
  "高3",
  "その他"
];

function convertRecordsToStudentRows(
  records: {
    [key: string]: string | StudentGrade | object[];
  }[]
): StudentRow[] {
  return records.map(record => {
    const customProperties = [];
    if (record.customProperty && record.customPropertyValue !== undefined) {
      customProperties.push({
        title: record.customProperty as string,
        value: record.customPropertyValue as string | number
      });
    }

    let i = 2; // 2から始める（既に最初の customProperty は処理されているため）
    while (
      record[`customProperty${i}`] &&
      record[`customPropertyValue${i}`] !== undefined
    ) {
      customProperties.push({
        title: record[`customProperty${i}`] as string,
        value: record[`customPropertyValue${i}`] as string | number
      });
      i++;
    }

    return {
      name: record.name as string,
      classroomName: record.classroomName as string,
      grade: record.grade as StudentGrade,
      id: "",
      password: record.password as string,
      loginId: (record.loginId ?? "") as string,
      customProperties: customProperties
    };
  });
}

function validateStudentRow(
  row: StudentRow,
  classroomNames: string[],
  customPropsTitles: string[]
) {
  if (row.name.length === 0) {
    throw new Error(
      "生徒名が入力されていない行が存在します。生徒名は入力必須です。"
    );
  }

  if (!classroomNames.includes(row.classroomName)) {
    throw new Error(
      "存在しない教室名が入力されています。データを修正して再度試してみてください。"
    );
  }

  if (!studentGrades.includes(row.grade)) {
    throw new Error(
      "存在しない学年が入力されています。データを修正して再度試してみてください。"
    );
  }

  // ログインIDが入力されなかった場合は、バックエンドでデフォルトのスクールIDとstudentIDを連結させる
  if (String(row.loginId).length > 0) {
    if (!/^[\x21-\x7E]+$/.test(row.loginId)) {
      throw new Error("ログインIDは半角英数字で設定してください。");
    }
    if (String(row.loginId).length < 5) {
      throw new Error("ログインIDは半角英数字5文字以上で設定してください。");
    }
  }

  if (row.password.length > 0 && !validPassword(row.password)) {
    throw new Error(
      "パスワードの形式が間違っている行が存在します。パスワードは半角英数字記号8文字以上64文字以内で入力してください。"
    );
  }

  if (row.customProperties) {
    const titles = new Set();
    for (const cp of row.customProperties) {
      if (!customPropsTitles.includes(cp.title)) {
        throw new Error(
          `以下のオリジナル情報が登録されていません: ${cp.title}`
        );
      }

      if (titles.has(cp.title)) {
        throw new Error(
          `同一のオリジナル情報を複数登録しようとしています。登録するオリジナル情報はかぶらないようにしてください: ${cp.title}`
        );
      }
      titles.add(cp.title);
    }
  }
}

function convertToNewStudentDataFromRow(
  row: StudentRow,
  classrooms: Classroom[],
  customProperties: CustomProperty[]
): NewStudentData {
  const matchClassrooms = classrooms.filter(
    c => c.data.name === row.classroomName
  );
  const matchCustomProperties = customProperties.filter(cp =>
    row.customProperties.some(rowCp => rowCp.title === cp.data.title)
  );

  if (matchClassrooms.length === 0) {
    throw new Error("correct classroom is not found.");
  }
  if (row.customProperties.length > 0 && matchCustomProperties.length === 0) {
    throw new Error("correct custom property is not found.");
  }

  const properties = row.customProperties
    ? row.customProperties.map(cp => {
        const title = cp.title;
        const matchedElement = customProperties.find(
          element => element.data.title === title
        );
        if (!matchedElement) {
          throw new Error(`No matched element found for title: ${title}`);
        }
        const cpRefId = matchedElement.ref.id;

        return { id: cpRefId, value: cp.value };
      })
    : [];

  return {
    classroomRef: matchClassrooms[0].ref,
    id: row.id,
    name: row.name,
    grade: row.grade,
    classSchedules: [],
    emailReceivers: [],
    password: row.password,
    properties: properties,
    loginId: row.loginId,
    // TODO: ↓CSVから取ったデータに含まれないので、空文字を渡すことで対応。今後、CSVからも登録できるようにする？
    characteristic: ""
  };
}

export async function validateCsvAndConvertToNewStudentDatas(
  file: File,
  classrooms: Classroom[],
  customProperties: CustomProperty[],
  existIds: string[]
): Promise<NewStudentData[]> {
  const ids = [...existIds];
  const text = await readAsText(file, "Shift_JIS");
  if (typeof text !== "string") {
    throw new Error(
      "ファイルを正常に読み込むことができませんでした。CSVファイルのデータが正しいかどうか確認してください"
    );
  }

  const records = parse(text, {
    columns: true,
    skipEmptyLines: true
  });

  if (records.constructor !== Array) {
    throw new Error(
      "ファイルを正常に読み込むことができませんでした。CSVファイルのデータが正しいかどうか確認してください"
    );
  }

  const max = 300;
  if (records.length > max) {
    throw new Error(`一度に登録できるのは${max}人までです。`);
  }

  const rows: StudentRow[] = convertRecordsToStudentRows(records);
  // validate rows (exist classroomName in classrooms / all id is unique / grade in studentGrades)
  for (const i in rows) {
    const index = Number(i);

    validateStudentRow(
      rows[index],
      classrooms.map(c => c.data.name),
      customProperties.map(c => c.data.title)
    );
    if (rows[index].password.length === 0) {
      rows[index].password = generateRandomPassword();
    }
    // idを自動で生成
    rows[index].id = generateNewStudentId(ids);
    ids.push(rows[index].id);
  }

  return rows.map(row =>
    convertToNewStudentDataFromRow(row, classrooms, customProperties)
  );
}

export function exportToShiftJisCsv(text: string, fileName: string) {
  const unicodeList = [];
  for (let i = 0; i < text.length; i += 1) {
    unicodeList.push(text.charCodeAt(i));
  }

  // 変換処理の実施
  const shiftJisCodeList = encoding.convert(unicodeList, "SJIS", "UNICODE");
  const uInt8List = new Uint8Array(shiftJisCodeList);

  // csv書き込みの実装
  const writeData = new Blob([uInt8List], { type: "text/csv" });

  fileSaver.saveAs(writeData, `${fileName}.csv`);
}
