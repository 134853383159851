
import { NewTutor, registerSchool, resisterChildSchool } from "@/api/school";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import router from "@/router";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { Role, School, SchoolConfig } from "@/entities/school";
import { generateNewSchoolId } from "@/utils/school";
import { Target } from "@/entities/target";
import { generateRandomPassword } from "@/utils/password";
import { saveErrorLog } from "@/api/error";
import { State } from "@/store/helper";
import { ParentSchool } from "@/entities/school";
import firebase from "firebase/app";

function isValidName(name: string): boolean {
  return name.length > 0;
}

function isValidEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function isValidPassword(password: string): boolean {
  return password.length >= 8;
}

@Options({
  components: {
    MBaseModal,
    MButton,
    MSelectBox,
    MTextField
  },
  emits: ["close"],
  props: {
    schools: Array,
    targets: Array,
    isSuperAdmin: Boolean,
    isServiceProvider: Boolean
  },
  watch: {
    addRole(newValue) {
      if (newValue === false) {
        this.role = [];
      }
    }
  }
})
export default class MScAddModal extends Vue {
  isSuperAdmin = false;
  isServiceProvider = false;
  schoolId = "";
  schoolName = "";
  enteredSchoolName = false;
  tutors: NewTutor[] = [
    {
      email: "",
      password: generateRandomPassword(),
      name: "",
      authority: "owner"
    }
  ];
  enteredEmailOfTutors: boolean[] = [false];
  enteredNameOfTutors: boolean[] = [false];
  selectedTargetId = "";
  schools: School[] = [];
  targets: Target[] = [];
  role: Role[] = [];
  addRole = false;
  useSchoolAiExclusively = false;
  hideLoginInvitaion = false;
  hideMingakuStudyRoom = false;
  allowStudentsToChangePassword = false;
  isPromptCreationForbidden = false;
  allowStudentsToCreatePrompts = false;

  @State("schoolConfig") schoolConfig?: SchoolConfig;
  @State("school") school?: School;

  get isSchool(): boolean {
    return store.state.isSchool;
  }

  get selectedTarget(): Target | null {
    const matchTargets = this.targets.filter(
      target => target.ref.id === this.selectedTargetId
    );
    if (matchTargets.length === 0) {
      return null;
    }
    return matchTargets[0];
  }

  get isSelectedTargetSchool(): boolean {
    return this.selectedTarget?.data.key === "school";
  }

  get validId(): boolean {
    return (
      /^\d{6}$/.test(this.schoolId) &&
      !this.schools.map(school => school.data.id).includes(this.schoolId)
    );
  }

  get validName(): boolean {
    return this.schoolName.length > 0;
  }

  get validTutors(): boolean {
    if (this.tutors.length === 0) {
      return false;
    }

    if (this.tutors.filter(tutor => tutor.authority === "owner").length !== 1) {
      return false;
    }

    for (const tutor of this.tutors) {
      if (
        !isValidName(tutor.name) ||
        !isValidEmail(tutor.email) ||
        !isValidPassword(tutor.password)
      ) {
        return false;
      }
    }
    return true;
  }

  get validData() {
    return this.validId && this.validName && this.validTutors;
  }

  get allEntered() {
    return (
      this.enteredSchoolName &&
      !this.enteredEmailOfTutors.includes(false) &&
      !this.enteredNameOfTutors.includes(false)
    );
  }

  addTutor() {
    this.tutors.push({
      email: "",
      password: generateRandomPassword(),
      name: "",
      authority: "general"
    });
    this.enteredEmailOfTutors.push(false);
    this.enteredNameOfTutors.push(false);
  }

  validEmailOf(email: string): boolean {
    return isValidEmail(email);
  }

  validPasswordOf(password: string): boolean {
    return isValidPassword(password);
  }

  allEnter() {
    this.enteredSchoolName = true;
    for (const i in this.enteredNameOfTutors) {
      const index = Number(i);
      this.enteredNameOfTutors[index] = true;
    }
    for (const i in this.enteredEmailOfTutors) {
      const index = Number(i);
      this.enteredEmailOfTutors[index] = true;
    }
  }

  getParentSchoolInfo(
    isServiceProvider: boolean,
    school: School | undefined,
    schoolConfig: SchoolConfig | undefined
  ) {
    if (!isServiceProvider) {
      return {};
    }

    if (!school || !schoolConfig) {
      alert("スクール情報が取得できません");
      throw new Error("スクール情報が取得できません");
    }

    const schoolDocRef: string = school.ref.path; // refのままだとFirebaseに保存することができない
    const schoolDocId: string = school.ref.id;
    const activateTime: number | undefined = schoolConfig.data.activateTime;
    const deactivateTime: number | undefined = schoolConfig.data.recessTime;

    const parentSchools: ParentSchool[] = [
      {
        schoolDocRef,
        schoolDocId,
        ...(activateTime ? { activateTime } : {}),
        ...(deactivateTime ? { deactivateTime } : {})
      }
    ];

    const parentSchoolDocIds: string[] = [schoolDocId];

    return { parentSchools, parentSchoolDocIds };
  }

  async resisterChildSchool(
    isServiceProvider: boolean,
    schoolConfigRef: firebase.firestore.DocumentReference | undefined,
    childSchoolDocRef: string,
    childSchoolDocId: string
  ) {
    if (!isServiceProvider || !schoolConfigRef) {
      return;
    }

    try {
      await resisterChildSchool(
        schoolConfigRef,
        childSchoolDocRef,
        childSchoolDocId
      );
    } catch (e) {
      alert("スクール登録中にエラーが発生しました" + e);
      console.log(e);
    }
  }

  async registerSchool() {
    this.allEnter();
    if (!this.validData) {
      return;
    }

    if (this.addRole && this.role.length === 0) {
      alert("役割を選択してください");
      return;
    }

    try {
      store.commit("START_LOADING", "新規スクール登録中...");
      const { parentSchools, parentSchoolDocIds } = this.getParentSchoolInfo(
        this.isServiceProvider,
        this.school,
        this.schoolConfig
      );
      const schoolInfo = {
        schoolId: this.schoolId,
        schoolName: this.schoolName.trim(),
        targetId: this.selectedTargetId,
        tutors: this.tutors,
        role: this.role,
        useSchoolAiExclusively: this.useSchoolAiExclusively,
        hideLoginInvitaion: this.hideLoginInvitaion,
        hideMingakuStudyRoom: this.hideMingakuStudyRoom,
        allowStudentsToChangePassword: this.allowStudentsToChangePassword,
        parentSchools,
        parentSchoolDocIds,
        isPromptCreationForbidden: this.isPromptCreationForbidden,
        allowStudentsToCreatePrompts: this.allowStudentsToCreatePrompts
      };

      const registrationResult = await registerSchool(
        this.isServiceProvider,
        schoolInfo
      );
      const {
        returnedSchoolDocumentRefs: schoolDocumentRefs,
        existingEmails,
        status
      } = registrationResult;

      if (status === "email-already-exists") {
        const emailString = existingEmails?.join("\n");
        alert(
          `${emailString}\nは既に登録されているメールアドレスです。別のメールアドレスをご登録ください。\n\n` +
            `※本プラットフォームは様々なサービスの基盤となっています。過去に登録されたアドレスも含めて、セキュリティの関係上、同じアドレスが使えない仕様になっているので、ご理解いただけますと幸いです。`
        );

        store.commit("END_LOADING");
        return;
      }

      if (!schoolDocumentRefs) {
        throw new Error("Document ref is not available");
      }

      const childSchoolDocRef = schoolDocumentRefs.schoolRef.path;
      const childSchoolDocId = schoolDocumentRefs.schoolRef.id;

      await this.resisterChildSchool(
        this.isServiceProvider,
        this.schoolConfig?.ref,
        childSchoolDocRef,
        childSchoolDocId
      );

      store.commit("END_LOADING");
      alert("新しい導入スクールの登録に成功しました");
      router.go(0);
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to register school"
      );
    }
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.schoolId = generateNewSchoolId(
      this.schools.map(school => school.data.id)
    );
    if (this.targets.length > 0) {
      this.selectedTargetId = this.targets[0].ref.id;
    }

    if (this.isServiceProvider) {
      this.useSchoolAiExclusively = true;
    }

    this.hideMingakuStudyRoom = this.isServiceProvider ? true : false;
  }
}
